import React, { ReactNode, useEffect, useRef, useState } from "react";
import { funcs } from "../firebaseobj";
import ReactRTE, { EditorValue } from "react-rte";
import { Button, Col, Container, Form, Row, InputGroup } from "react-bootstrap";
import RichTextEditor from "react-rte";

let SureButton = ({
  sure,
  children,
}: {
  sure: () => void;
  children: (setter: any) => ReactNode;
}) => {
  let [surevisible, setSureVisible] = useState(false);

  if (surevisible) {
    return (
      <Button
        variant="warning"
        onBlur={() => setSureVisible(false)}
        onClick={(event) => {
          event.preventDefault();
          sure();
          setSureVisible(false);
        }}
      >
        Sure?
      </Button>
    );
  } else {
    return <>{children(setSureVisible)}</>;
  }
};
const EmailEditor = () => {
  const [model, setModel] = useState(RichTextEditor.createEmptyValue());
  const [subject, setSub] = useState("TechDigitalCard Newsletter");
  const [dis, setDis] = useState(false);
  const sendNewsletter = async () => {
    setDis(true);
    let newsfunc = funcs.httpsCallable("newsFuncs-mailAll");
    let data = { content: model.toString("html"), subject };
    let ret = await newsfunc(data);
    setDis(false);
  };

  return (
    <Container>
      <Row>
        <Col>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>Subject:</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="text"
              value={subject}
              onChange={(e) => {
                setSub(e.target.value);
              }}
            />
          </InputGroup>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <ReactRTE
            editorStyle={{ minHeight: "100px" }}
            value={model}
            onChange={(changed) => {
              setModel(changed);
              console.info(changed.toString("html"));
            }}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col className="d-flex justify-content-center">
          <SureButton sure={() => sendNewsletter()}>
            {(setsure) => {
              return (
                <Button
                  disabled={dis}
                  onClick={() => {
                    setsure(true);
                  }}
                >
                  Send Mail
                </Button>
              );
            }}
          </SureButton>
        </Col>
      </Row>
    </Container>
  );
};
export default EmailEditor;
const styles = {
  editor: {
    border: "1px solid gray",
    minHeight: "6em",
  },
};
