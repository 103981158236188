import React, { ReactNode, useContext } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import NavBar from "./components/NavBar/NavBar";
import Main from "./components/Main/Main";
import Login from "./components/Login/Login";
import Templates from "./components/Templates/Templates";
import { AuthContext } from "./components/AuthProvider/AuthProvider";
import Terms from "./components/Terms";
import UsersSuper from "./components/UsersSuper/UsersSuper";
import { User } from "firebase";
import FranSuper from "./components/Franchise/FranSuper";
import OrderSuper from "./components/Orders/OrderSuper";
import EmailEditor from "./components/Editor/Editor";
let ProtectedComponent = ({
  user,
  children,
}: {
  user: User | null;
  children: ReactNode;
}) => {
  if (user || sessionStorage.getItem("wasloggedin")) return <>{children}</>;
  else return <Redirect to={"/login"} />;
};

function App() {
  let { userstate: user } = useContext(AuthContext);

  return (
    <BrowserRouter forceRefresh={true}>
      <NavBar />
      <Switch>
        <Route path="/login">
          <Login user={user} />
        </Route>
        <Route path={["/privacypolicy", "/terms"]}>
          <Terms />
        </Route>
        <Route path="/users">
          <ProtectedComponent user={user}>
            <UsersSuper />
          </ProtectedComponent>
        </Route>
        <Route path="/templates">
          <ProtectedComponent user={user}>
            <Templates />
          </ProtectedComponent>
        </Route>
        <Route path="/fran">
          <ProtectedComponent user={user}>
            <FranSuper />
          </ProtectedComponent>
        </Route>
        <Route path="/order">
          <ProtectedComponent user={user}>
            <OrderSuper />
          </ProtectedComponent>
        </Route>
        <Route path="/newsletter">
          <ProtectedComponent user={user}>
            <EmailEditor />
          </ProtectedComponent>
        </Route>

        <Route path={"/"} exact={true}>
          <ProtectedComponent user={user}>
            <Main />
          </ProtectedComponent>
        </Route>

        {/*<Route path="/users">
          <Users />
        </Route>
        <Route path="/" exact={true}>
          <Main />
        </Route>*/}
      </Switch>
    </BrowserRouter>
  );
}

export default App;
