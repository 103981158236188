import React from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import Orders from "./Orders";
import OrderManage from "./OrderManage";

const OrderSuper = () => {
  return (
    <BrowserRouter basename="order">
      <Switch>
        <Route path="/list" exact={true}>
          <Orders />
        </Route>
        /*
        <Route path="/:id">
          <OrderManage />
        </Route>
        <Redirect to="/list" />
      </Switch>
    </BrowserRouter>
  );
};
export default OrderSuper;
