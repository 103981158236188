import React from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import Users from "../Users/Users";
import UsersManage from "../UserManage/UsersManage";

const UsersSuper = () => {
  return (
    <BrowserRouter basename="users">
      <Switch>
        <Route path="/list" exact={true}>
          <Users />
        </Route>
        <Route path="/:id">
          <UsersManage />
        </Route>
        <Redirect to="/list" />
      </Switch>
    </BrowserRouter>
  );
};
export default UsersSuper;
