import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { fstore } from "../firebaseobj";
import path from "../paths.json";
import { ExtraParams } from "../Interfaces";
//import PieChart from "./Charts/PieChart";
//import Graph from "./Charts/AnimatedLine";

let Main = () => {
  let [extraparamsdoc, setExtraParams] = useState({} as ExtraParams);
  useEffect(() => {
    let extraparams = fstore.doc(path.extraparams);
    let unsubscribe = extraparams.onSnapshot((data) => {
      setExtraParams(data.data() as ExtraParams);
    });
    return () => {
      unsubscribe();
    };
  }, []);
  return (
    <Container>
      <Row>
        <Col className="h4 text-center text-monospace text-gray-900 font-weight-bold mt-5">
          Dashboard
        </Col>
      </Row>
      <Row>
        <Col md className="mb-2">
          <Card className="shadow border-left-primary">
            <Card.Body>
              <Container>
                <Row className="text-xs font-weight-bold text-uppercase text-primary">
                  <Col>User Count</Col>
                </Row>
                <Row>
                  <Col className="h3 font-weight-bold">
                    {extraparamsdoc.users ?? "NA"}
                  </Col>
                  <Col>
                    <i className="float-right pi pi-users h3" />
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Col>
        <Col md className="mb-2">
          <Card className="shadow border-left-warning">
            <Card.Body>
              <Container>
                <Row className="text-xs font-weight-bold text-uppercase text-warning">
                  <Col>Franchisee Count</Col>
                </Row>
                <Row>
                  <Col className="h3 font-weight-bold">
                    {extraparamsdoc.fran ?? "NA"}
                  </Col>
                  <Col>
                    <i className="float-right pi pi-shopping-cart h3" />
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Col>
        <Col md className="mb-2">
          <Card className="shadow border-left-success">
            <Card.Body>
              <Container>
                <Row className="text-xs font-weight-bold text-uppercase text-success">
                  <Col>Revenue</Col>
                </Row>
                <Row>
                  <Col className="h3 font-weight-bold">
                    {extraparamsdoc.revenue !== undefined
                      ? "₹" + extraparamsdoc.revenue / 100
                      : "NA"}
                  </Col>
                  <Col>
                    <i className="float-right pi pi-money-bill h3" />
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/*   <Row>
        <Col className="my-2">
          <PieChart />
        </Col>

        <Col className="my-2">
          <Card>
            <Card.Header className="bg-dark text-light">Dataset 2</Card.Header>
            <Card.Body>
              <Graph />
            </Card.Body>
          </Card>
        </Col>
      </Row>*/}
    </Container>
  );
};
export default Main;
