import React from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";

let Templates = () => {
  return (
    <Container>
      <Row>
        <Col>
          <Card className="shadow">
            <Card.Header>
              <span className="text-primary font-weight-bold">
                Upload a new template
              </span>
            </Card.Header>
            <Card.Body>
              <Form>
                <Form.Group>
                  <Form.Label>Template Name</Form.Label>
                  <Form.Control type={"text"} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Description</Form.Label>
                  <Form.Control type={"text"} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>File</Form.Label>
                  <Form.Control
                    type={"file"}
                    placeholder="Zip file with Template"
                  />
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Templates;
