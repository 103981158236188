import React, { useEffect, useReducer, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import { fstore } from "../firebaseobj";
import * as paths from "../paths.json";
import dayjs from "dayjs";
import { Line } from "react-chartjs-2";

//@ts-ignore
import html2pdf from "html2pdf.js";
const FranGenerate = (props: {
  labels: Array<Date>;
  frandata: any;
  data?: Array<number>;
  pdata?: Array<number>;
}) => {
  let dat;
  if (props.data) dat = props.data.concat();
  let pdat;
  if (props.pdata) pdat = props.pdata.concat();
  const frandata = props.frandata;

  //Reverse as dates are in reverse order
  const labels = props.labels
    .reverse()
    .map((lab) => dayjs(lab).format("YYYY-MM"));

  const rev_data: any = {
    labels: labels,
    datasets: [
      {
        label: "Revenue",
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: dat?.reverse(),
      },
    ],
  };
  const pay_data: any = {
    labels: labels,
    datasets: [
      {
        label: "Payments",
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgb(164,64,125)",
        borderColor: "rgb(192,75,134)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgb(228,88,161)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: pdat?.reverse(),
      },
    ],
  };
  console.info(rev_data);
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: function (value: any) {
              return "₹" + value;
            },
          },
        },
      ],
    },
  };
  return (
    <Container>
      <Row>
        <Col>
          <Table striped bordered>
            <tbody>
              {Object.entries(frandata)
                .sort()
                .map((entry, index, array) => {
                  if (index < array.length / 2)
                    return (
                      <tr key={entry[0]}>
                        <td>{entry[0]}</td>
                        <td>
                          <>{entry[1]}</>
                        </td>
                      </tr>
                    );
                })}
            </tbody>
          </Table>
        </Col>
        <Col>
          <Table striped bordered>
            <tbody>
              {Object.entries(frandata)
                .sort()
                .map((entry, index, array) => {
                  if (index > array.length / 2)
                    return (
                      <tr key={entry[0]}>
                        <td>{entry[0]}</td>
                        <td>
                          <>{entry[1]}</>
                        </td>
                      </tr>
                    );
                })}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row className="pg">
        <Col>
          {props.data?.length ? <Line data={rev_data} options={options} /> : ""}
        </Col>
      </Row>
      <Row className="pg">
        <Col>
          {props.data?.length ? <Line data={pay_data} options={options} /> : ""}
        </Col>
      </Row>
    </Container>
  );
};

const FranReport = () => {
  const [frandata, setFranData] = useState({}) as any;
  const [startdate, setStartDate] = useState(new Date(0) as Date);
  const [enddate, setEndDate] = useState(new Date(0) as Date);
  const [smonths, setMonths] = useState([] as Array<Date>);
  const [sdatapoints, setDataPoints] = useState([] as Array<number>);
  const [spaymentpoints, setPaymentPoints] = useState([] as Array<number>);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  function handleClick() {
    forceUpdate();
  }

  //Only reduced datapoints
  const paymentpoints: Array<number> = [];
  const datapoints: Array<number> = [];
  useEffect(() => {
    const handler = async () => {
      //Franchise id in collection
      const franid = window.location.href.split("/")[4];

      //Franchise default document
      const frandetails: any = (
        await fstore.collection(paths.frans).doc(franid).get()
      ).data();
      setFranData(frandetails);
      // console.debug(frandetails);

      //Query DB for orders satisfying range
      //@ts-ignore
      if (startdate != "Invalid Date" && enddate != "Invalid Date") {
        const franorders = (
          await fstore
            .collection(paths.orders)
            .where("fran", "==", frandetails.name)
            .where("created_at", ">=", startdate.valueOf() / 1000)
            .where("created_at", "<=", enddate.valueOf() / 1000)
            .orderBy("created_at", "desc")
            .get()
        ).docs;
        //TODO: Make the payments fit into UI
        //TODO: Put all payments logic inside an if statement that checks for payments exist
        const payments = (
          await fstore
            .collection(paths.frans)
            .doc(frandetails.name)
            .collection(paths.payments)
            .where("date", "<", enddate.valueOf())
            .where("date", ">=", startdate.valueOf())
            .orderBy("date", "desc")
            .get()
        ).docs;

        //Iterate through docs

        const franordersdata: Array<any> = [];
        franorders.forEach((doc) => {
          franordersdata.push(doc.data());
        });
        console.info([franordersdata, "data"]);

        //Getting all the months (technically dates) for the labels in the chart
        const months: Array<Date> = [];
        months.push(enddate);
        let stuff = new Date(enddate);
        while (stuff.valueOf() > startdate.valueOf()) {
          stuff = dayjs(stuff).subtract(1, "month").toDate();
          months.push(stuff);
        }
        console.info(months);

        for (let i = 0; i < months.length - 1; i++) {
          const filtered = franordersdata.filter((val) => {
            /*console.log(
              val.created_at,
              "val",
              months[i].valueOf() / 1000,
              months[i + 1].valueOf() / 1000
            );*/
            return (
              val.created_at < months[i].valueOf() / 1000 &&
              val.created_at >= months[i + 1].valueOf() / 1000
            );
          });

          console.info([filtered, "filtered"]);
          const reduced = filtered.reduce(
            (acc, cur) => acc + cur.amount_paid,
            0
          );
          datapoints.push(reduced / 100);
        }
        if (payments) {
          const paymentsdata: Array<any> = [];
          payments.forEach((payment) => {
            paymentsdata.push(payment);
          });
          for (let i = 0; i < months.length - 1; i++) {
            const filteredpayments = paymentsdata.filter((val) => {
              return (
                val.date < months[i].valueOf() &&
                val.date >= months[i + 1].valueOf()
              );
            });
            const reducedpayments = filteredpayments.reduce(
              (acc, cur) => acc + cur.amount_paid,
              0
            );
            paymentpoints.push(reducedpayments);
          }
        }
        setPaymentPoints(paymentpoints);
        setDataPoints(datapoints);
        months.pop();
        setMonths(months);
        console.info(datapoints);
      }
    };
    handler();
  }, [ignored]);
  //TODO: implement date validation on fields
  return (
    <Container className="card card-body shadow">
      <Row>
        <Col>
          <Form>
            <h1 className="text-center">Select Date Range</h1>
            <Form.Row>
              <Col lg={{ span: 4, offset: 2 }}>
                <Form.Group>
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control
                    placeholder="yyyy-mm"
                    type="month"
                    min={"2020-07"}
                    max={Date.now()}
                    onChange={(e) => {
                      const date = new Date(e.target.value);
                      setStartDate(date);
                      console.info([date, "start"]);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col lg={{ span: 4 }}>
                <Form.Group>
                  <Form.Label>End Date</Form.Label>
                  <Form.Control
                    placeholder="yyyy-mm"
                    type="month"
                    min={"2020-07"}
                    max={Date.now()}
                    onChange={(e) => {
                      const date = new Date(e.target.value);
                      setEndDate(date);
                      console.info([date, "end"]);
                    }}
                  />
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col className="d-inline-flex justify-content-end">
                <Button size="lg" onClick={() => forceUpdate()}>
                  Search
                </Button>
              </Col>
              <Col>
                <Button
                  size="lg"
                  onClick={() => {
                    let print = document.getElementById("print");
                    html2pdf(print, {
                      margin: 1,
                      filename: `${frandata.name}_${dayjs(Date.now()).format(
                        "YYYY-MM-DD"
                      )}`,
                      image: { type: "jpeg", quality: 0.98 },
                      pagebreak: { before: ".pg" },
                      html2canvas: {
                        scale: 2,
                      },
                      jsPDF: {
                        orientation: "l",

                        putOnlyUsedFonts: true,
                      },
                    });
                  }}
                >
                  Print
                </Button>
              </Col>
            </Form.Row>
          </Form>
        </Col>
      </Row>

      <Row className="mt-2">
        <Col>
          <div id="print">
            <Container>
              <Row className="text-center">
                <Col>
                  <h3 className="text-black-50 mb-5">
                    {frandata.name} {dayjs(Date.now()).format("YYYY-MM-DD")}
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FranGenerate
                    labels={smonths}
                    frandata={frandata}
                    data={sdatapoints}
                    pdata={spaymentpoints}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default FranReport;
