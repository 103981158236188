import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { authobj } from "../firebaseobj";

let NavBar = () => {
  const textnone = { textDecoration: "none", color: "inherit" };
  return (
    <Navbar className="shadow bg-gradient-dark mb-2" variant="dark" expand="lg">
      <Navbar.Brand>
        <Link to="/" style={textnone}>
          TechDigitalCard
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link as="div">
            <Link to="/" style={textnone}>
              Home <i className="pi pi-home align-baseline" />
            </Link>
          </Nav.Link>
          <Nav.Link as="div">
            <Link to="/login" style={textnone}>
              Login <i className="pi pi-sign-in align-baseline" />
            </Link>
          </Nav.Link>
          <Nav.Link
            onClick={() => {
              authobj.signOut().then(() => {
                window.location.reload();
              });
            }}
          >
            Signout <i className="pi pi-sign-out align-baseline" />
          </Nav.Link>
          <Nav.Link as="div">
            <Link to="/users/list" style={textnone} replace={true}>
              Users <i className="pi pi-users align-baseline" />
            </Link>
          </Nav.Link>
          {/*<Nav.Link as="div">
            <Link to="/templates" style={textnone}>
              Template <i className="pi pi-palette align-baseline" />
            </Link>
          </Nav.Link>*/}
          <Nav.Link as="div">
            <Link to="/fran" style={textnone}>
              Franchise <i className="pi pi-ticket align-baseline" />
            </Link>
          </Nav.Link>
          <Nav.Link as="div">
            <Link to="/order" style={textnone}>
              Orders <i className="pi pi-money-bill align-baseline" />
            </Link>
          </Nav.Link>{" "}
          <Nav.Link as="div">
            <Link to="/newsletter" style={textnone}>
              Mail <i className="pi pi-envelope align-baseline" />
            </Link>
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};
export default NavBar;
