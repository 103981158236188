import React, { useEffect, useMemo, useReducer, useState } from "react";
import { fstore } from "../firebaseobj";
import paths from "../paths.json";
import { useSortBy, useTable } from "react-table";
import { firestoreErrorHandler } from "../common";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Table,
} from "react-bootstrap";

import { Link } from "react-router-dom";

class HandlePages {
  private _reference: firebase.firestore.CollectionReference;
  private _perpage: number;
  private _query!: firebase.firestore.Query;
  private _search: string;
  private _index: string;
  private _setChanged?: any;
  constructor(
    reference: firebase.firestore.CollectionReference,
    perpage?: number,
    index?: string,
    search?: string
  ) {
    this._reference = reference;
    if (perpage) {
      this._perpage = perpage;
    } else this._perpage = 15;
    if (index) {
      this._index = index;
    } else this._index = "fullname";
    if (search) {
      this._search = search;
    } else this._search = "";
    this.base_query();
    return this;
  }
  public base_query = () => {
    this._query = this._reference
      .orderBy(this._index)
      .where(this._index, ">=", this._search.toUpperCase())
      .limit(this._perpage);
    if (this._setChanged) {
      this._setChanged();
    }
  };
  public next = (last: any) => {
    this._query = this._reference
      .orderBy(this._index)
      .startAfter(last[this._index])
      .where(this._index, ">=", this._search.toUpperCase())
      .limit(this._perpage);
    if (this._setChanged) {
      this._setChanged();
    }
  };
  public prev = (first: any) => {
    this._query = this._reference
      .orderBy(this._index)
      .endBefore(first[this._index])
      .where(this._index, ">=", this._search.toUpperCase())
      .limit(this._perpage);
    if (this._setChanged) {
      this._setChanged();
    }
  };

  get reference(): firebase.firestore.CollectionReference {
    return this._reference;
  }

  set reference(value: firebase.firestore.CollectionReference) {
    this._reference = value;
    this.base_query();
  }

  get perpage(): number {
    return this._perpage;
  }

  set perpage(value: number) {
    this._perpage = value;
    this.base_query();
  }

  get query(): firebase.firestore.Query {
    return this._query;
  }

  get search(): string {
    return this._search;
  }

  set search(value: string) {
    this._search = value;
    this.base_query();
  }

  get index(): string {
    return this._index;
  }

  set index(value: string) {
    this._index = value;
    this.base_query();
  }

  set setChanged(value: (rand: number) => void) {
    this._setChanged = value;
  }
}

const Search = ({
  setIndex,
  setSearch,
  indices,
}: {
  setIndex: (index: string) => void;
  setSearch: (search: string) => void;
  indices: Array<string>;
}) => {
  return (
    <InputGroup>
      <Form.Control
        placeholder="Search..."
        onChange={(event) => {
          setSearch(event.target.value);
        }}
      />
      <InputGroup.Append>
        <Form.Control
          style={{ borderBottomLeftRadius: 0, borderTopLeftRadius: 0 }}
          as="select"
          id="index-select"
          onChange={(event) => setIndex(event.target.value)}
          custom
        >
          {indices.map((ind) => (
            <option key={ind}>{ind}</option>
          ))}
        </Form.Control>
      </InputGroup.Append>
    </InputGroup>
  );
};
let usersref = fstore.collection(paths.usercollection);
const queryhandler = new HandlePages(usersref, 15, "fullname");

let Users = () => {
  let [users, setUsers] = useState([] as Array<any>);
  let [ids, setIds] = useState([] as Array<any>);
  //0:no check, 1: unpaid, 2:paid
  let [checkvalid, setCheck] = useState(0);
  const [ignored, forceUpdate] = useReducer((x: number) => x + 1, 0);

  function handleClick() {
    forceUpdate();
  }
  const indices = ["fullname", "username", "emailid", "mobile"];

  queryhandler.setChanged = handleClick;
  function dataSetter(data: firebase.firestore.QuerySnapshot) {
    let [users, ids]: [Array<any>, Array<any>] = [[], []];
    let userjson: any = {};

    data.forEach(async (doc) => {
      const dat = doc.data();
      const id = doc.id;
      let sub: any = {};
      if (checkvalid) {
        sub = (
          await fstore
            .doc(`${paths.usercollection}/${id}/${paths.subscription}`)
            .get()
        ).data();
      }
      if (
        !Number(checkvalid) ||
        (sub.endtime && Number(checkvalid) === 1 && sub.endtime < Date.now()) ||
        (sub.endtime && Number(checkvalid) === 2 && sub.endtime > Date.now())
      ) {
        if (
          dat[queryhandler.index]
            .toUpperCase()
            .indexOf(queryhandler.search.toUpperCase()) > -1
        ) {
          ids.push(doc.id);
          users.push(dat);
          userjson[doc.id] = doc.data();
        }
      }
    });
    setTimeout(() => {
      setUsers(users);
      setIds(ids);
    }, 800);
  }

  useEffect(() => {
    let listener = queryhandler.query.onSnapshot(
      dataSetter,
      firestoreErrorHandler
    );
    return () => listener();
  }, [ignored, checkvalid]);
  const otherheadings = useMemo(() => {
    return ["emailid", "username", "fullname", "fran", "mobile"].map((key) => {
      return {
        Header: key.toUpperCase(),
        accessor: key,
      };
    });
  }, []);
  let tab = useTable({ columns: otherheadings, data: users }, useSortBy);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tab;
  return (
    <Container className="mt-2">
      <Row>
        <Col className="my-3">
          <Search
            indices={indices}
            setIndex={(index) => {
              queryhandler.index = index;
            }}
            setSearch={(search) => {
              queryhandler.search = search;
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col className="my-3">
          <div className="d-inline-flex w-100 justify-content-center">
            <Form.Check
              custom
              value={1}
              onChange={(event: any) => {
                setCheck(event.target.value);
              }}
              type="radio"
              label={"unpaid"}
              name="paid"
              id={"unpaid"}
              className="mx-3"
            />
            <Form.Check
              custom
              value={2}
              onChange={(event: any) => {
                setCheck(event.target.value);
              }}
              type="radio"
              name="paid"
              label={"paid"}
              id={"paid"}
              className="mx-3"
            />{" "}
            <Form.Check
              custom
              value={0}
              onChange={(event: any) => {
                setCheck(event.target.value);
              }}
              type="radio"
              name="paid"
              label={"none"}
              id={"none"}
              className="mx-3"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="d-flex justify-content-center">
            <Form.Control
              className="w-25"
              custom
              as="select"
              onChange={(evt) => {
                queryhandler.perpage = Number(evt.target.value);
              }}
            >
              <option disabled={true}>No. per page</option>
              <option>{5}</option>
              <option>{10}</option>
              <option selected={true}>{15}</option>
              <option>{20}</option>
              <option>{25}</option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          {" "}
          <Table
            variant="info"
            borderless={true}
            className="rounded p-2 text-gray-900"
            responsive={true}
            {...getTableProps}
          >
            <thead className="text-center" style={{ lineBreak: "strict" }}>
              {
                // Loop over the header rows
                headerGroups.map((headerGroup) => (
                  // Apply the header row props
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {
                      // Loop over the headers in each row
                      headerGroup.headers.map((column) => (
                        // Apply the header cell props

                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("Header")}
                          <br />
                          {/* Add a sort direction indicator */}
                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <i className="pi pi-angle-down" />
                              ) : (
                                <i className="pi pi-angle-up" />
                              )
                            ) : (
                              <>
                                <i className="pi pi-angle-up" />
                                <i className="pi pi-angle-down" />
                              </>
                            )}
                          </span>
                        </th>
                      ))
                    }
                    <th className="align-text-top">Subscription</th>
                  </tr>
                ))
              }
            </thead>
            <tbody className="text-center" {...getTableBodyProps()}>
              {
                // Loop over the table rows
                rows.map((row, index) => {
                  // Prepare the row for display
                  prepareRow(row);
                  return (
                    // Apply the row props
                    <tr {...row.getRowProps()}>
                      {
                        // Loop over the rows cells
                        row.cells.map((cell) => {
                          // Apply the cell props
                          return (
                            <td {...cell.getCellProps()}>
                              {
                                // Render the cell contents
                                cell.render("Cell")
                              }
                            </td>
                          );
                        })
                      }
                      <td>
                        <Button>
                          <Link
                            style={{ textDecoration: "none", color: "inherit" }}
                            to={ids[index]}
                          >
                            Manage
                          </Link>
                        </Button>
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </Table>
        </Col>
      </Row>

      <Row>
        <Col>
          <Button
            className="float-right"
            onClick={() => queryhandler.prev(users[0])}
          >
            {"<"}
          </Button>
        </Col>
        <Col>
          <Button onClick={() => queryhandler.next(users[users.length - 1])}>
            {">"}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
export default Users;
