import React from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import Franchise from "./Franchise";
import FranManage from "./FranManage";
import FranReport from "./FranReport";

const FranSuper = () => {
  return (
    <BrowserRouter basename="fran">
      <Switch>
        <Route path="/list" exact={true}>
          <Franchise />
        </Route>
        <Route path="/:id/report">
          <FranReport />
        </Route>
        <Route path="/:id">
          <FranManage />
        </Route>
        <Redirect to="/list" />
      </Switch>
    </BrowserRouter>
  );
};
export default FranSuper;
