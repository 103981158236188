import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";
const firebaseConfig = {
  apiKey: "AIzaSyAbHOBOP6m8G6Ri0cN-zvp3ALjgjbZNENw",
  authDomain: "digi-card-62aff.firebaseapp.com",
  databaseURL: "https://digi-card-62aff.firebaseio.com",
  projectId: "digi-card-62aff",
  storageBucket: "digi-card-62aff.appspot.com",
  messagingSenderId: "890714650240",
  appId: "1:890714650240:web:d9db9678e9dc6404a8d865",
  measurementId: "G-N9G0LWFKNH",
};
firebase.initializeApp(firebaseConfig);
export const authobj = firebase.auth();
export const fstore = firebase.firestore();
export const funcs = firebase.functions();
