import React, { ReactNode, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import paths from "../paths.json";
import { fstore, funcs } from "../firebaseobj";
let SureButton = ({
  sure,
  children,
}: {
  sure: () => void;
  children: (setter: any) => ReactNode;
}) => {
  let [surevisible, setSureVisible] = useState(false);

  if (surevisible) {
    return (
      <Button
        variant="warning"
        onBlur={() => setSureVisible(false)}
        onClick={(event) => {
          event.preventDefault();
          sure();
          setSureVisible(false);
        }}
      >
        Sure?
      </Button>
    );
  } else {
    return <>{children(setSureVisible)}</>;
  }
};
let OrderManage = () => {
  let [order, setOrder] = useState(null as any);
  let uid = document.URL.substring(document.URL.lastIndexOf("/") + 1);
  useEffect(() => {
    let userouterref = fstore.collection(paths.orders);
    let userref = userouterref.doc(uid);
    let userSubscription = userref.onSnapshot(
      (user) => {
        if (user.exists) setOrder(user.data() as any);
      },
      (error) => console.error(error)
    );

    return () => {
      userSubscription();
    };
  }, []);
  function isEmpty(obj: any) {
    for (var x in obj) {
      return false;
    }
    return true;
  }

  if (order && !isEmpty(order)) {
    let keys = Object.keys(order).filter(
      (ke) => ["notes", "created_at"].indexOf(ke) === -1
    );
    keys.sort();
    // keys.unshift("fname", "name", "emailid", "users_reg", "mobile");
    let data = keys.map((key) => {
      return (
        <Form.Group key={key} controlId={key}>
          <Form.Label>
            <span className="text-capitalize">{key}</span>
          </Form.Label>
          <Form.Control type="text" value={order[key]} readOnly={true} />
        </Form.Group>
      );
    });

    return (
      <Container>
        <Row>
          <Col>
            <Card className="shadow">
              <Card.Header className="bg-gray-100	text-primary">
                <b>Order</b>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Form.Group>
                    <Form.Label>
                      <span className="text-capitalize">
                        Order Creation Time
                      </span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={order["created_at"].toLocaleString()}
                      readOnly={true}
                    />
                  </Form.Group>
                  <>{data}</>
                  {Object.entries(order.notes).map((note) => (
                    <Form.Group key={note[0]}>
                      <Form.Label>
                        <span className="text-capitalize">{note[0]}</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={String(note[1])}
                        readOnly={true}
                      />
                    </Form.Group>
                  ))}
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  } else return <div>No Order provided</div>;
};

export default OrderManage;
