import React, { ReactNode, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import paths from "../paths.json";
import { fstore, funcs } from "../firebaseobj";
import { Link } from "react-router-dom";
let SureButton = ({
  sure,
  children,
}: {
  sure: () => void;
  children: (setter: any) => ReactNode;
}) => {
  let [surevisible, setSureVisible] = useState(false);

  if (surevisible) {
    return (
      <Button
        variant="warning"
        onBlur={() => setSureVisible(false)}
        onClick={(event) => {
          event.preventDefault();
          sure();
          setSureVisible(false);
        }}
      >
        Sure?
      </Button>
    );
  } else {
    return <>{children(setSureVisible)}</>;
  }
};
let FranManage = () => {
  let [user, setUser] = useState(null as any);
  let uid = document.URL.substring(document.URL.lastIndexOf("/") + 1);
  useEffect(() => {
    let userouterref = fstore.collection(paths.frans);
    let userref = userouterref.doc(uid);
    let userSubscription = userref.onSnapshot(
      (user) => {
        if (user.exists) setUser(user.data() as any);
      },
      (error) => console.error(error)
    );

    return () => {
      userSubscription();
    };
  }, []);
  function isEmpty(obj: any) {
    for (var x in obj) {
      return false;
    }
    return true;
  }

  if (user && !isEmpty(user)) {
    let keys = Object.keys(user).filter(
      (ke) =>
        [
          "approval",
          "fname",
          "name",
          "emailid",
          "users_reg",
          "mobile",
          "payments",
          "revenue",
        ].indexOf(ke) === -1
    );
    keys.sort();
    keys.unshift("fname", "name", "emailid", "users_reg", "mobile");
    let data = keys.map((key) => {
      return (
        <Form.Group key={key} controlId={key}>
          <Form.Label>
            <span className="text-capitalize">{key}</span>
          </Form.Label>
          <Form.Control type="text" value={user[key]} readOnly={true} />
        </Form.Group>
      );
    });

    let pays = () => {
      if (user.payments) {
        return `₹${
          (user.payments as Array<number>).reduce((acc, val) => acc + val) / 100
        }`;
      } else return "NA";
    };
    let rtrack = (slice: number) => {
      if (user.rtrack) {
        if (slice > user.rtrack.length) slice = user.rtrack.length - 1;
        return `₹${
          (user.rtrack as Array<number>)
            .slice(user.rtrack.length - 1 - slice)
            .reduce((acc, val) => acc + val) / 100
        }`;
      } else return "NA";
    };
    return (
      <Container>
        <Row className="mb-2">
          <Col className="d-inline-flex justify-content-center">
            <Button>
              <Link
                style={{
                  width: "100%",
                  textDecoration: "none",
                  color: "inherit",
                }}
                to={(location) => location.pathname + "/report"}
              >
                Report
              </Link>
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="shadow">
              <Card.Header className="bg-gray-100	text-primary">
                <b>Franchise</b>
              </Card.Header>
              <Card.Body>
                <div className="my-2">
                  <ApproveButton approval={user.approval} uid={uid} />
                </div>
                <Form>
                  <Form.Group>
                    <Form.Label>Revenue</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        disabled
                        value={`₹${Number(user.revenue) / 100}`}
                      />
                      <InputGroup.Append>
                        <SureButton
                          sure={() => {
                            const resetPayments = funcs.httpsCallable(
                              "franFuncs-resetPayments"
                            );
                            resetPayments({
                              id: uid,
                              amount: user.revenue,
                            }).catch((err) => {
                              console.log(err);
                              alert(err);
                            });
                            console.log("sure");
                          }}
                        >
                          {(setter) => {
                            return (
                              <Button onClick={() => setter(true)}>
                                Reset
                              </Button>
                            );
                          }}
                        </SureButton>
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>{" "}
                  <Form.Group>
                    <Form.Label>Total Payments</Form.Label>

                    <Form.Control type="text" disabled value={pays()} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Revenue in last 7 days</Form.Label>

                    <Form.Control type="text" disabled value={rtrack(7)} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Revenue in last 7 days</Form.Label>

                    <Form.Control type="text" disabled value={rtrack(30)} />
                  </Form.Group>
                  <>{data}</>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  } else return <div>No Franchise provided</div>;
};
let ApproveButton = (props: { approval: boolean; uid: string }) => {
  const toggleFunc = funcs.httpsCallable("franFuncs-toggleFran");

  if (!Boolean(props.approval)) {
    return (
      <Button
        variant="success"
        onClick={() => {
          toggleFunc({ id: props.uid })
            .then(() => console.log("Approved!"))
            .catch((err) => {
              alert(err);
              console.error(err);
            });
        }}
        block
      >
        Approve
      </Button>
    );
  } else
    return (
      <Button
        variant="danger"
        block
        onClick={() => {
          toggleFunc({ id: props.uid })
            .then(() => console.log("Approved!"))
            .catch((err) => {
              alert(err);
              console.error(err);
            });
        }}
      >
        De-Approve
      </Button>
    );
};
export default FranManage;
